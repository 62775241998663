.mainheading {
    font-family: "Oswald", sans-serif;
    font-size: 26px;
}

.mainheading1 {
    font-family: "Oswald", sans-serif;
    font-size: 20px;
}

.subheading {
    font-family: "Robot0", sans-serif;
    font-weight: 400;

}

.becomemember {
    font-family: "Oswald", sans-serif;
    font-size: 26px;
    color: #fa993a;
}

.sliderimg {
    width: 100%;
    height: 550px !important;
}

.btncall {
    font-size: 14px !important;
    text-align: center !important;
    color: #178b44 !important;
    background: #e7f0f8 !important;
}

.btncall:hover {
    background: #fa993a !important;
    color: #fff !important;
}

.contactnumber {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 600 !important;
}

.ourptext {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.7;
    text-align: justify;
}

/*** Topbar Start ***/
.bgtopbar {
    background: #fa993a;
    color: #fff;
}

.topbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    vertical-align: middle;
    margin-left: 8px;
    color: #178b44;
}

.topbar .dropdown .dropdown-menu a:hover {
    background: #178b44 !important;
    color: #000 !important;
}

.topbar .dropdown .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    background: var(--bs-light);
    padding-top: 10px;
    border: 0;
    transition: .5s;
    opacity: 1;
}

/*** Topbar End ***/


/*** Navbar Start ***/
.nav-bar {
    background: var(--bs-white);
    transition: 1s;
}

.logo {

    max-width: 700px;
    height: auto;
}

@media (max-width: 768px) {
    .logo {
        max-width: 300px;
    }
}

@media (max-width: 480px) {
    .logo {
        max-width: 200px;
    }
}

.navbar .navbar-nav .nav-item .nav-link {
    padding: 0;
}

.navbar .navbar-nav .nav-item {
    display: flex;
    align-items: center;
    padding: 15px;
}

.navbar-light .navbar-nav .nav-item:hover,
.navbar-light .navbar-nav .nav-item.active,
.navbar-light .navbar-nav .nav-item:hover .nav-link,
.navbar-light .navbar-nav .nav-item.active .nav-link {
    color: #54aee2;
    font-weight: 600;
}

@media (max-width: 991px) {
    .navbar {
        padding: 20px 0;
    }

    .navbar .navbar-nav .nav-link {
        padding: 0;
    }

    .navbar .navbar-nav .nav-item {
        display: flex;
        padding: 20px;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }

    .navbar .navbar-nav .nav-btn {
        display: flex;
        justify-content: start;
    }

    .navbar .navbar-nav {
        width: 100%;
        display: flex;
        margin-top: 20px;
        padding-bottom: 20px;
        background: var(--bs-light);
        border-radius: 10px;
    }

    .navbar.navbar-expand-lg .navbar-toggler {
        padding: 8px 15px;
        border: 1px solid #000;
        color: #000;
    }
}

.navbar .dropdown-toggle::after {
    /* border: none;
    content: "\f107"; */
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    vertical-align: middle;
    margin-left: 4px;
}

.dropdown .dropdown-menu .dropdown-item:hover {
    background: #178b44 !important;
    color: #fff;
    font-family: "Roboto Condensed", sans-serif;
}

@media (min-width: 992px) {
    .navbar {
        padding: 20px 0;
    }

    .navbar .nav-btn {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .navbar .navbar-nav {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--bs-light);
        border-radius: 10px;
    }

    .navbar .navbar-nav .nav-btn {
        width: 100%;
        display: flex;
        margin-left: auto;
    }

    .navbar .nav-item .dropdown-menu {
        display: block;
        visibility: hidden;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
        border: 0;
        border-radius: 10px;
        transition: .5s;
        opacity: 0;
    }

    .navbar .nav-item:hover .dropdown-menu {
        transform: rotateX(0deg);
        visibility: visible;
        margin-top: 20px;
        background: var(--bs-light);
        transition: .5s;
        opacity: 1;
    }
}

/*** Navbar End ***/
/*** Footer start ***/

.ftco-section {
    padding: 7em 0;
    position: relative;
}

@media (max-width: 767.98px) {
    .ftco-section {
        padding: 6em 0;
    }
}

.ftco-bg-dark {
    background: #090808;
}

.ftco-footer {
    padding: 6em 0;
    border-top: 3px solid #fa993a;
}

.ftco-footer .ftco-footer-widget h2 {
    font-weight: normal;
    color: #2c396b;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
}

.ftco-footer-widget {
    line-height: 1.7 !important;
}

.ftco-footer .ftco-footer-widget ul li a span {
    color: #000;
}

.ftco-footer .ftco-footer-widget .btn-primary {
    background: #fff !important;
    border: 2px solid #fff !important;
}

.ftco-footer .ftco-footer-widget .btn-primary:hover {
    background: #fff;
    border: 2px solid #fff !important;
}

.ftco-footer a {
    color: rgba(0, 0, 0, 0.5);
}

.ftco-footer a:hover {
    color: #000;
}

.ftco-footer .ftco-heading-2 {
    font-size: 18px;
    font-weight: 800 !important;
    color: #fa993a !important;
}

.ftco-footer .categories li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ftco-footer .categories li a span {
    color: rgba(255, 255, 255, 0.5) !important;
}

.ftco-footer-social li {
    list-style: none;
    margin: 0 10px 0 0;
    display: inline-block;
}

.ftco-footer-social li a {
    height: 40px;
    width: 40px;
    display: block;
    color: #fa993a !important;
}

.ftco-footer-social li a span {
    font-size: 30px !important;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff !important;
}

.ftco-footer-social li a:hover {
    color: #fff;
}

.footer-text {
    color: #000 !important;
    font-size: 16px;
    font-weight: 400 !important;
}

.footer-text:hover {
    color: #2c396b !important;
    font-size: 16px;
    font-weight: 600 !important;
}

/*** Fotter End ***/
/*** Slider Start ***/
.slides {
    height: 500px;
}

/*** Slider End ***/

/*** Service Start ***/
.row.g-4 {
    display: flex;
    flex-wrap: wrap;
}

.service-item {
    display: flex;
    flex-direction: column;
    height: 400px;
}

.service-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.service-content-inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.service .service-item {
    border-radius: 10px;
}

.focusimg {
    width: 100%;
    height: 220px !important;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: 0.5s;
}

.focusimg:hover {
    transform: scale(1.1);
}

.focusimg::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: rgba(1, 95, 201, .2);
    transition: 0.5s;
    z-index: 1;
}

.focusimg::after {
    height: 100%;
}

.service .service-content {
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #deebf7;
}

.service .service-item .service-content .service-content-inner {
    position: relative;
    z-index: 9;
}

.service .service-item .service-content .service-content-inner .h4,
.service .service-item .service-content .service-content-inner p {
    transition: 0.5s;
}

.service .service-item:hover .service-content .service-content-inner .h4,
.service .service-item:hover .service-content .service-content-inner p {
    color: #fff !important;
}

.h5 {
    color: #000;
    font-family: "Poppins", sans-serif;
    font-style: normal;
}

.h5:hover {
    color: #fff !important;
}

.service .service-item:hover .service-content .service-content-inner .btn.btn-primary {
    color: #fff;
    background: #2573b6 !important;
}

.btn-focus {
    background: #fa993a !important;
    color: #fff !important;
    font-weight: 600 !important;
}

.service .service-item:hover .service-content .service-content-inner .h4:hover {
    color: var(--bs-dark);
}

.service .service-item .service-content::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #178b44;
    transition: 0.5s;
    z-index: 1;
}

.service .service-item:hover .service-content::after {
    height: 100%;
}

/*** Service End ***/

/* marqee */
/* Marquee.css */
.marquee {
    background-color: #2573b6;
    color: #fff;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 600 !important;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
}

.marquee-content {
    padding-top: 10px;
    display: inline-block;
    padding-left: 100%;
    animation: marquee 15s linear infinite;
}

.marquee-content:hover {
    animation-play-state: paused;
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

/* vission */
.vissionbg {
    background-color: #2573b6;
    color: #fff;
}

.vissionmissioniocns {
    color: #178b44;
}

/* donation */
.donationform {
    border-radius: 10px !important;
    margin-bottom: 30px !important;
}

.donationform::placeholder {
    color: white !important;
    padding: 15px 10px !important;
}

/* join now */
.tutotbg {
    background-color: #178b44;
    color: #fff;
}

.becometutorbtn {
    background: #fff;
    color: #000;
    padding: 15px 50px !important;
    font-size: 22px;
    font-weight: 600;
}

/* why choose us */
.whychooseheading {
    font-family: "Poppins", sans-serif;
    font-size: 33px;
    color: #fa993a;
}


/* why choose us end */
/* video full screen */
.custom-video {
    width: 100%;
    height: 500px;
    /* Adjust this to your preferred height */
    border: none;
}

/*** news Start ***/
.blog .blog-item {
    border-radius: 10px;
    transition: 0.5s;
}

.blog .blog-item:hover {
    box-shadow: 0 0 45px rgba(0, 0, 0, .1);
}

.blog .blog-item:hover .blog-content .newheading {
    color: #fa993a !important;
    background: var(--bs-white);
}

.newsimg {
    height: 260px !important;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: 0.5s;
}

.newsimg:hover {
    transform: scale(1.1);
}

.newsimg::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: rgba(1, 95, 201, .2);
    transition: 0.5s;
    z-index: 1;
}

.newsimg::after {
    height: 100%;
}

.newsbtn {
    color: #000;
    font-size: 20px !important;
    font-weight: 600 !important;

}

.blog .blog-item .blog-img .blog-categiry {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top-left-radius: 10px;
    font-weight: 600 !important;
    display: inline-flex;
    color: #fff !important;
    background: #178b44 !important;
    z-index: 9;
}

.blog .blog-item .blog-content {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #deebf7;
}

.newsbtn:hover {
    color: #fa993a !important;
    font-weight: 600 !important;
}

.newheading {
    color: #000 !important;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-size: 20px !important;
}

/*** news End ***/
/* other sector */
.bg-banner {
    background: linear-gradient(rgba(217, 217, 239, 0.9), rgba(238, 238, 251, 0.9)), url('./Assets/images/syhobanner_bg.jpg');
    background-attachment: fixed;
    background-position: center;
    height: auto;
    background-size: cover;
}

.bg-vission {
    background: linear-gradient(rgb(246, 211, 180), rgb(241, 202, 149)), url('./Assets/images/sihobanner.png');
    background-attachment: fixed;
    background-position: center;
    height: auto;
    background-size: cover;
}

.donationtext {
    font-size: 40px;
    font-family: "Archivo", sans-serif;
}

.quote-icon {
    color: #fa993a;

}

.bannerbtn {
    background-color: #fa993a;
    color: white;
    border-radius: 30px;
    padding: 15px 30px;
    font-family: "Archivo", sans-serif;
}

.othersectorheading {
    font-family: "Oswald", sans-serif;
    font-size: 28px;
    color: #fa993a;
    font-weight: 600 !important;
}

.othersectorheading2 {
    font-family: "Oswald", sans-serif;
    font-size: 38px;
    color: #fa993a;
    font-weight: 800 !important;
}

/*** Feature Start ***/
.feature-item {
    border-radius: 5px;
    background: #fff !important;
    transition: 0.5s;
}

.feature-item:hover,
.othersechead:hover {
    background: #178b44 !important;
    color: #fff;
}

.feature-item .feature-icon {
    position: relative;
    width: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fa993a;
}

.feature-item:hover .feature-icon i {
    z-index: 9;
}

.feature-item .feature-icon::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fff !important;
    color: #000 !important;
    transition: 0.5s;
    z-index: 1;
}

.feature-item:hover .feature-icon::after {
    height: 100%;
    color: #000 !important;
}

.feature-item .feature-icon {
    color: #2573b6 !important;
}

.feature-item .feature-icon,
.feature-item h4,
.feature-item p {
    transition: 0.5s;
}

.feature-item:hover .feature-icon {
    color: var(--bs-white);
}

.feature-item:hover h4,
.feature-item:hover p {
    color: #fff !important;
}

.feature-item:hover a.btn {
    background: #fff !important;
    color: #000 !important;
}

.feature-item:hover a.btn:hover {
    color: #000 !important;
}

.equal-height-row {
    display: flex;
    flex-wrap: wrap;
}

.equal-height-row .col-lg-4,
.equal-height-row .col-md-4 {
    display: flex;
    flex-direction: column;
}

.feature-item {
    flex-grow: 1;
}

.othersechead {
    color: #fa993a !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-family: "Poppins", sans-serif;
}

.othersechead:hover {
    color: #fff !important;
    font-weight: 800 !important;
}

.othersecbtnred {
    color: #000;
    font-size: 16px !important;
    font-weight: 600 !important;

}

/*** Feature End ***/
/* partner slider */
.slick-slide {
    margin-right: 15px;
    /* Adds space between slides */
}

.slide {
    border-radius: 10px;
    box-sizing: border-box;

}

.partnerimg {
    background: #fff;
    width: 100%;
    height: 150px;
    object-fit: contain;
    border-radius: 10px;
    padding: 10px !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    /* Shadow around the image */
}


.ftco-gallery {
    padding: 0;
}

.gallery {
    display: block;
    height: 300px;
    position: relative;
    margin-bottom: 30px;
}

.gallery .icon {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    z-index: 0;
    opacity: 0;
    position: relative;
    -moz-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    -ms-transition: all 0.6s ease;
    transition: all 0.6s ease;
    background: #ffb5b5;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.gallery .icon span {
    color: #fff;
}

.gallery:hover .icon,
.gallery:focus .icon {
    opacity: 1;
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item+.info-item {
    margin-top: 40px;
}

.contact .info-item i {
    color: #000;
    background: #e0e0d7;
    font-size: 20px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    margin-right: 15px;
}

.contact .info-item h3 {
    font-family: Helvetica;
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}

.contact .info-item p {
    font-family: Inter;
    padding: 0;
    margin-bottom: 0;
    font-size: 16px;
}

.contact .php-email-form {
    height: 100%;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
    font-size: 14px;
    padding: 10px 20px;
    box-shadow: none;
    border-radius: 0;
    color: #000 !important;
    background-color: #e0e0d7;
    border-color: none;
    border-radius: 20px;
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
    border-color: #e0e0d7;
}

.contact .php-email-form input[type=text]::placeholder,
.contact .php-email-form input[type=email]::placeholder,
.contact .php-email-form textarea::placeholder {
    color: #000;
}

.contact .php-email-form button[type=submit] {
    color: #000;
    background: #e0e0d7;
    font-family: Helvetica;
    font-weight: 600;
    border: 0;
    padding: 10px 36px;
    transition: 0.4s;
    border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
    font-weight: 800;
}

.contactbg {
    background: #178b44;
    padding-top: 100px;
    padding-bottom: 80px;
}

.presedentimg {
    width: 400px;
    height: 450px !important;
    text-align: center;
}

.focussectorimg {
    width: 400px;
    height: 300px !important;
    text-align: center;
}
.videotitle{
    font-size: 20px !important;
}
.presstext{

}

/* common head */
.common-head {
    background: linear-gradient(rgba(233, 213, 185, 0.9), rgba(62, 103, 62, 0.9)), url('./Assets/images/commonforiacbanner.png');
    background-size: cover;
    background-repeat: repeat;
}

.chooseus {
    width: 600px !important;
    height: 480px !important;
}

/* administrative */
.administrativemain {
    font-family: "Oswald", sans-serif;
    color: #178b44 !important;
    font-size: 28px;
}

.administrativesub {
    font-family: "Lobster Two", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #fa993a !important;
    font-size: 24px;
}

.liheading {
    font-family: "Poppins", sans-serif !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

/* contact us */
.contactinfo {
    background-color: #178b44;
}

.contacticon {
    color: #fff;
    font-size: 22px;
}

.submitbtn {
    background-color: #178b44;
    color: #fff;
    font-size: 22px;
    padding: 10px 25px;
    border: none;
    border-radius: 5px;
}

.submitbtndonation {
    background-color: #178b44;
    color: #fff;
    font-size: 18px;
    padding: 6px 8px;
    border: none;
    border-radius: 5px;
}

.addbtn {
    background: #178b44 !important;
    color: #fff !important;
}

/* mershipform registration */

.ul-style {
    padding-top: 15px !important;
    padding-left: 20px !important;
}

.benefitstablebg {
    background-color: #178b44 !important;
    text-align: center;
    border: 1px solid white !important;
}

.myborder {
    border-color: 1px solid #fff !important;
}

/* gallery */
.img-thumbnail {
    width: 420px !important;
    height: 340px !important;
}

/* event news */
.servicecarbg {
    background: #c1ddcd;
}
#top1{
    display: block !important;
}

/* career */
.servicecarbg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 300px !important;
    /* Set a fixed height */
    width: 100% !important;
    /* Ensure full width of the column */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
    transition: transform 0.3s ease;
}

.servicecarbg:hover {
    transform: translateY(-5px);
    /* Hover effect */
}

@media (min-width: 768px) {
    .servicecarbg {
        height: 270px !important;

    }
  
}
@media only screen and (max-width: 768px) {
    #top1 {
        display: none !important;
    }
    #top2 {
        display: none !important;
    }
    .fincos{
        font-size: 12px !important;
    }
}


.vicons {
    background: #fa993a;
    text-align: center;
    padding: 15px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    font-size: 32px !important;
    color: #fff !important;
}

.carouselbtn {
    background: #fa993a;
    color: #fff;
    border: none;
    padding: 7px 10px;
    border-radius: 5px;
}

.blog-post {
    -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}

.blog-post .blog-img .overlay,
.blog-post .blog-img .post-meta {
    position: absolute;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.blog-post .blog-img .overlay {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.blog-post .blog-img .post-meta {
    bottom: 5%;
    right: 5%;
    z-index: 1;
}

.blog-post .blog-img .post-meta .read-more:hover {
    color: #6dc77a !important;
}

.blog-post .content h1,
.blog-post .content h2,
.blog-post .content h3,
.blog-post .content h4,
.blog-post .content h5,
.blog-post .content h6 {
    line-height: 1.2;
}

.blog-post .content .title {
    font-size: 18px;
}

.blog-post .content .title:hover {
    color: #6dc77a !important;
}

.blog-post .content .author .name:hover {
    color: #6dc77a !important;
}

.blog-post:hover {
    -webkit-transform: translateY(-7px);
    transform: translateY(-7px);
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.blog-post:hover .blog-img .overlay {
    opacity: 0.65;
}

.blog-post:hover .blog-img .post-meta {
    opacity: 1;
}

.blog-post .post-meta .like i,
.profile-post .like i {
    -webkit-text-stroke: 2px #dd2427;
    -webkit-text-fill-color: transparent;
}

.blog-post .post-meta .like:active i,
.blog-post .post-meta .like:focus i,
.profile-post .like:active i,
.profile-post .like:focus i {
    -webkit-text-stroke: 0px #dd2427;
    -webkit-text-fill-color: #dd2427;
}

.avatar.avatar-ex-sm {
    height: 36px;
}

.shadow {
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15) !important;
}

.text-muted {
    color: #8492a6 !important;
}

.para-desc {
    max-width: 600px;
}

.text-muted {
    color: #8492a6 !important;
}

.section-title .title {
    letter-spacing: 0.5px;
    font-size: 30px;
}

/* Blog 
---------------------*/
.blog-grid {
    box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
    border-radius: 5px;
    overflow: hidden;
    background: #ffffff;
    margin-top: 15px;
    margin-bottom: 15px;
}

.blog-grid .blog-img {
    position: relative;
}

.blog-grid .blog-img .date {
    position: absolute;
    background: #fc5356;
    color: #ffffff;
    padding: 8px 15px;
    left: 10px;
    top: 10px;
    border-radius: 4px;
}

.blog-grid .blog-img .date span {
    font-size: 22px;
    display: block;
    line-height: 22px;
    font-weight: 700;
}

.blog-grid .blog-img .date label {
    font-size: 14px;
    margin: 0;
}

.blog-grid .blog-info {
    padding: 20px;
}

.blog-grid .blog-info h5 {
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 10px;
}

.blog-grid .blog-info h5 a {
    color: #20247b;
}

.blog-grid .blog-info p {
    margin: 0;
}

.blog-grid .blog-info .btn-bar {
    margin-top: 20px;
}


/* Blog Sidebar
  -------------------*/
.blog-aside .widget {
    box-shadow: 0 0 30px rgba(82, 130, 185, 0.125);
    border-radius: 5px;
    overflow: hidden;
    background: #ffffff;
    margin-top: 35px;
    margin-bottom: 15px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
}

.blog-aside .widget-body {
    padding: 20px;
}

.blog-aside .widget-title {
    padding: 15px;
    border-bottom: 1px solid #eee;
}

.blog-aside .widget-title h3 {
    font-size: 22px;
    font-weight: 800;
    color: #178b44;
    margin: 0;
    text-decoration: none !important;
}

.blog-aside .latest-post-aside+.latest-post-aside {
    border-top: 1px solid #eee;
    padding-top: 15px;
    margin-top: 15px;
}

.blog-aside .latest-post-aside .lpa-right {
    width: 90px;
}

.blog-aside .latest-post-aside .lpa-right img {
    border-radius: 3px;
}

.blog-aside .latest-post-aside .lpa-left {
    padding-right: 15px;
}

.blog-aside .latest-post-aside .lpa-title h5 {
    margin: 0;
    font-size: 15px;
    text-decoration: none !important;
}

.blog-aside .latest-post-aside .lpa-title a {
    color: #20247b;
    font-weight: 600;
    text-decoration: none !important;
}

.blog-aside .latest-post-aside .lpa-meta a {
    color: #6F8BA4;
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
    margin-right: 10px;
    text-decoration: none !important;
}


.article {
    border-radius: 5px;
    overflow: hidden;
    background: #ffffff;
    padding: 20px;
    margin: 15px 0 30px;
}

.article .article-title {
    padding: 15px 0 20px;
}

.article .article-title h6 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
}

.article .article-title h6 a {
    text-transform: uppercase;
    color: #fa993a;
}

.article .article-title h2 {
    color: #178b44 !important;
    font-weight: 600;
}

.article .article-title .media {
    padding-top: 15px;
    border-bottom: 1px dashed #ddd;
    padding-bottom: 20px;
}

.article .article-title .media .avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
}

.article .article-title .media .media-body {
    padding-left: 8px;
}

.article .article-title .media .media-body label {
    font-weight: 600;
    color: #fc5356;
    margin: 0;
}

.article .article-title .media .media-body span {
    display: block;
    font-size: 12px;
}

.article-comment h4 {
    color: #20247b;
    font-weight: 700;
    margin-bottom: 25px;
    font-size: 22px;
}

img {
    max-width: 100%;
}

img {
    vertical-align: middle;
    border-style: none;
}

/* Contact Us
  ---------------------*/
.contact-name {
    margin-bottom: 30px;
}

.contact-name h5 {
    font-size: 22px;
    color: #20247b;
    margin-bottom: 5px;
    font-weight: 600;
}

.contact-name p {
    font-size: 18px;
    margin: 0;
}

.social-share a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
    margin-right: 10px;
}

.social-share .dribbble {
    box-shadow: 0 8px 30px -4px rgba(234, 76, 137, 0.5);
    background-color: #ea4c89;
}

.social-share .behance {
    box-shadow: 0 8px 30px -4px rgba(0, 103, 255, 0.5);
    background-color: #0067ff;
}

.social-share .linkedin {
    box-shadow: 0 8px 30px -4px rgba(1, 119, 172, 0.5);
    background-color: #0177ac;
}

.contact-form .form-control {
    border: none;
    border-bottom: 1px solid #20247b;
    background: transparent;
    border-radius: 0;
    padding-left: 0;
    box-shadow: none !important;
}

.contact-form .form-control:focus {
    border-bottom: 1px solid #fc5356;
}

.contact-form .form-control.invalid {
    border-bottom: 1px solid #ff0000;
}

.contact-form .send {
    margin-top: 20px;
}

@media (max-width: 767px) {
    .contact-form .send {
        margin-bottom: 20px;
    }
}

.section-title h2 {
    font-weight: 700;
    color: #20247b;
    font-size: 45px;
    margin: 0 0 15px;
    border-left: 5px solid #fc5356;
    padding-left: 15px;
}

.section-title {
    padding-bottom: 45px;
}

.contact-form .send {
    margin-top: 20px;
}

.px-btn {
    padding: 0 50px 0 20px;
    line-height: 60px;
    position: relative;
    display: inline-block;
    color: #20247b;
    background: none;
    border: none;
}

.px-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 30px;
    background: transparent;
    border: 1px solid rgba(252, 83, 86, 0.6);
    border-right: 1px solid transparent;
    -moz-transition: ease all 0.35s;
    -o-transition: ease all 0.35s;
    -webkit-transition: ease all 0.35s;
    transition: ease all 0.35s;
    width: 60px;
    height: 60px;
}

.px-btn .arrow {
    width: 13px;
    height: 2px;
    background: currentColor;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 25px;
}

.px-btn .arrow:after {
    width: 8px;
    height: 8px;
    border-right: 2px solid currentColor;
    border-top: 2px solid currentColor;
    content: "";
    position: absolute;
    top: -3px;
    right: 0;
    display: inline-block;
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.genralbackground {
    background: #178b44 !important;
}

.contactinfobg {
    background: #fa993a !important;
}


/* opd slip */
.opdspan {
    font-size: 16px;
    color: #000;
}

.opdmainh {
    font-family: Helvetica;
    font-size: 18px !important;
    font-weight: 600 !important;
}

#datesopd {
    display: none !important;
}

#datelopd {
    display: block;
}

.opdsubh {
    font-family: Helvetica;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.eopd {
    font-size: 14px !important;

}

.aptpcontact {
    display: block;
}

.aptpcontactm {
    display: none;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    .opdmainh {
        font-size: 16px !important;
    }

    .opdspan {
        font-size: 14px;
        color: #000;
    }

    #datelopd {
        display: none !important;
    }

    #datesopd {
        display: block !important;
    }

    .opdsubh {
        font-size: 14px !important;
    }

    .eopd {
        font-size: 12px !important;
    }

    #apt {
        display: none;
    }

    .aptp {
        font-size: 12px !important;
    }

    .aptpcontact {
        display: none;
    }

    .aptpcontactm {
        display: block;
    }
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .opdmainh {
        font-size: 14px !important;
    }

    .opdspan {
        font-size: 12px;
        color: #000;
    }

    #datelopd {
        display: none !important;
    }

    #datesopd {
        display: block;
    }
}

/* membership form */
.backgroundmembership {
    background: #178b44;
}

.formheading {
    font-family: Helvetica;
    font-size: 28px !important;
    font-weight: 600 !important;
}

.genralflabel {
    font-family: "Roboto", sans-serif;
    font-size: 18px !important;
    font-weight: 400 !important;
}

.genralinputs {
    border: 1px solid #fff;
    background-color: transparent !important;
    border-radius: 10px !important;
    padding: 8px 10px !important;
    color: #fff !important;
    outline: #fff !important;
}

.genralinputs:focus {
    border: 1px solid #fff;
    outline: #fff !important;
}

/* Custom styles for the select element */
.genralselect {
    appearance: none !important;
    border: 1px solid #fff;
    background-color: transparent !important;
    border-radius: 10px !important;
    padding: 8px 15px !important;
    color: #fff !important;
    outline: #fff !important;
    cursor: pointer;
}

.genralselect:focus {
    color: #fff;
    outline: none;
}

/* Style the options */
.genralselect option {
    background-color: #fa993a !important;
    color: #fff;
}

.genralselect .option:hover {
    background-color: #178b44;
    color: #fff;
}

.contactinputs {
    border: 1px solid #f4f2f2 !important;
    background-color: #fff !important;
    border-radius: 10px !important;
    padding: 10px 15px !important;
    color: #000 !important;

}

.pop-button {
    animation: bounce 1s infinite;
    /* Simple bounce animation */
}

#stackholderinmobile {
    display: none;
}

#stackholderindesktop {
    display: block;
}

.map {
    width: 100%;
    max-width: 1600px;
    height: 380px;
}

@media (max-width: 768px) {
    .map {
        height: 300px;
    }
}

@media (max-width: 576px) {
    .map {
        height: 250px;
    }
}


@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
        /* Original position */
    }

    40% {
        transform: translateY(-10px);
        /* Move up */
    }

    60% {
        transform: translateY(-5px);
        /* Move down */
    }
}

/* Add this CSS in your styles or a separate CSS file */
@media (max-width: 768px) {
    .service-item {
        display: flex;
        flex-direction: column;
        /* Changes direction to column */
        align-items: center;
        /* Center-aligns content in the column */
        text-align: center;
        /* Centers the text */
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .wow {
        visibility: visible !important;
        animation-name: none !important;
    }

    .sliderimg {
        width: 100%;
        height: 220px !important;
    }

    .whychooseheading {
        font-size: 22px;
    }

    .subheading {
        font-size: 16px;
    }

    .chooseus {
        width: 550px !important;
        height: 250px !important;
    }

    .becomemember {
        font-size: 20px;
    }

    .mainheading {
        font-size: 18px;
    }

    .becometutorbtn {
        padding: 10px 0px !important;
        font-size: 18px;
        font-weight: 600;
    }

    #jointbn {
        margin-top: 40px !important;
    }

    #stackholderinmobile {
        display: block;
    }

    #stackholderindesktop {
        display: none;
    }

    .service-item {
        height: 320px !important;
    }

    .service-content {
        display: flex;

    }

    .focusimg {
        height: 190px !important;
        width: 330px !important;
        overflow: hidden;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        transition: 0.5s;
    }

    .focusimg:hover {
        transform: scale(1.1);
    }

    #diet {
        width: 328px !important;
    }

    .stackimg {
        width: 250px !important;
    }

    #stactcontent {
        width: 305px !important;
    }

    .mobilespace {
        padding: 0px 20px !important;
    }

    .mobilecol {
        display: none;
    }

    #mobileinput {
        font-size: 14px;
        padding: 5px 5px;
        box-shadow: none;
        border-radius: 0;
        color: #000 !important;
        background-color: #e0e0d7;
        border-color: none;
        border-radius: 20px;
    }

    .presedentimg {
        width: 400px !important;
        height: 350px !important;
    }

    .landrspace {
        padding: 10px 30px !important;
    }

    .allmemberspace {
        padding: 0px 20px !important;
    }

    .administrativesub {
        font-weight: 600;
        font-size: 16px !important;
    }

    .administrativemain {
        font-size: 20px;
    }

    #allsecheading {
        font-size: 20px !important;
        padding-top: 30px !important;
        font-weight: 700 !important;
    }

    .formheading {
        font-family: Helvetica;
        font-size: 22px !important;
        font-weight: 600 !important;
    }

    .genralflabel {
        font-size: 16px !important;
        font-weight: 400 !important;
    }

    /* .map{
    width:375px !important;
    height:380px !important ;
} */
}

@media (max-width: 768px) {
    .wow {
        visibility: visible !important;
        animation-name: none !important;
    }
}